import {IActionModel} from "../../shared/models/action.model";
import {
    LOGOUT, SET_LOGGED_IN_USER_TOKEN, SET_LOGGED_USER_DATA
} from "../actions/account.action";
import {CommonService} from "../../shared/services";
import {Misc} from "../../constants";
import Communications from "../../shared/services/communications.service";
import {ILoggedInUser} from "../../shared/models/account.model";

export interface IAccountReducerState {
    currentUser?: ILoggedInUser;
    token?: string | null;
}

const INITIAL_STATE: IAccountReducerState = {
    currentUser: undefined,
    // token: CommonService._localStorage.getItem(Misc.LOCAL_STORAGE_JWT_TOKEN),
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2UzNmM2NThhNmQ2N2JlN2VjZmRhMDkiLCJjbGllbnRfaWQiOiIwMDAwMDA4OCIsImZpcnN0X25hbWUiOiJNYW5vaiBLdW1hciAiLCJsYXN0X25hbWUiOiIgQiIsInByaW1hcnlfZW1haWwiOiJtYW5vanZhcm1hQHRlcmljc29mdC5jb20iLCJwcmltYXJ5X2NvbnRhY3RfaW5mbyI6eyJwaG9uZV90eXBlIjoibW9iaWxlIiwicGhvbmUiOiI4ODg4ODg4ODg4ODgiLCJwaG9uZV90eXBlX2RldGFpbHMiOnsiX2lkIjoiNjM3ZjQyMGRkOGE3Y2YwMTBmMTQ5MGI2IiwidGl0bGUiOiJNb2JpbGUiLCJjb2RlIjoibW9iaWxlIn19LCJpYXQiOjE2NzYxMjEyNDksImV4cCI6MTY3NjI5NDA0OX0.3Q_UPT4QVbaPMklIQMeB95YD_4eX-SRuHudA1TW62rU"
};

const accountReducer = (state: IAccountReducerState = INITIAL_STATE, action: IActionModel): IAccountReducerState => {
    switch (action.type) {
        case SET_LOGGED_USER_DATA:
            const loggedInUser = action.payload;
            CommonService._localStorage.setItem(Misc.LOCAL_STORAGE_LOGGED_IN_USER_DATA, loggedInUser);
            return {
                ...state,
                currentUser: loggedInUser,
            };
        case SET_LOGGED_IN_USER_TOKEN:
            const token = action.payload;
            CommonService._localStorage.setItem(Misc.LOCAL_STORAGE_JWT_TOKEN, token);
            Communications.updateLoginUserTokenSubject.next(token);
            return {
                ...state,
                token: token,
            };
        case LOGOUT:
            Communications.updateLoginUserTokenSubject.next('');
            return {
                ...state,
                token: undefined,
                currentUser: undefined
            };
        default:
            return state;
    }
};

export default accountReducer;

