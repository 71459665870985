import "./IconButtonComponent.scss";
import {PropsWithChildren, useCallback} from "react";

interface IconButtonComponentProps {
    className?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    id?: string;
    onClick?: () => void;
}

const IconButtonComponent = (props: PropsWithChildren<IconButtonComponentProps>) => {

    const {
        className,
        id,
        disabled,
        onClick,
        children,
    } = props;

    const handleOnClick = useCallback(() => {
        if (onClick) {
            onClick();
        }
    }, [onClick]);

    return (
        <div className={`icon-button ${className} ${disabled ? 'disabled' : ''}`}
             id={id}
             onClick={handleOnClick}
        >
            {children}
        </div>
    );

};

export default IconButtonComponent;
